import {Pages}                   from "./Pages";
import ReactDOM                  from "react-dom";
import {PersonDependentDropList} from "../components/PersonDependentDropList";
import React                     from "react";
import {PersonDependentElements} from "../enums/PersonDependentElements";

export class Teachers extends Pages {

    initPageHandlers(params) {
        super.initPageHandlers(params);
        this.data = window.diaryJsData;

        ReactDOM.render(
            <PersonDependentDropList
                entity={PersonDependentElements.TEACHER}/>,
            document.getElementById('list-search-form-component'),
        );

    }
}
